<template>
	<div class="block-inner">
		<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
	</div>
</template>

<script>
	import RichEditor from '../RichEditor';

	export default {
		components: {
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		}
	};
</script>